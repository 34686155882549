@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Snowburst+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Uncial+Antiqua&display=swap');


body{
	height: auto;
	font-family: 'Libre Baskerville', serif;
	line-height: normal;
	color: black;
	font-size:16px;
	font-weight:400;
    background: url(./image/dice.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
}


.App{
  text-align: center;
}

ul.ant-menu{
  text-align: center;
}

.choix p{
	color: rgba(150, 40, 27, 1);
	font-weight: bold;
}

.pbg{
	background-color: rgba(238, 238, 238,0.8);
	margin-top: 2rem;
	padding: 2rem;
}

h1{
	padding-bottom: 1rem;
}

article{
	text-align: justify;
}

.left{
	text-align: left;
	margin-bottom: 2rem;
}

p{
	color: black; 
	font-weight: 500;
}

li{
	list-style: none;
}

.italic{
	font-style: italic;
}

.bold{
	font-weight: bold;
}

a:hover{
	text-decoration: none;
}

img{
	max-width: 100%;
	height: auto;
}

main{
	margin: 0 auto;
	max-width: 80%;
}

.buttons{
	margin-top: 1rem;
	margin-bottom: 1rem;
}

button.btn{
	font-weight: bold;
	margin-right: 1rem;
	background-color: rgba(238, 238, 238,0.9);
}

button.btn:last-child{
	margin-right: 0;
}

.cards{
	margin-top: 4rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	color: black;
}

.card {
	margin: 20px 47px;
	box-shadow: 0px 3px 5px rgba(0,0,0,0.2);
	border-radius: 15px;
	justify-content: center;
	background-color: rgba(238, 238, 238,0.8);
}

.card-img-top{
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.card-title {
	margin-bottom:none;
}

.edition {
	font-size: 14px;
}

.card-body {
	flex:none;
	min-height:1px;
	padding: 1.25rem 1.1rem;
}

.card-body a{
	color: rgba(150, 40, 27, 1);
}

.legrog{
	background-color: rgba(238, 238, 238,0.8);
	width: 18rem;
	margin: 0 auto;
	padding: 0.7rem;
	border-radius: 15px;
}

footer{
	margin-top: 1rem;
}

main a, footer p, div .notFound{
	color: rgba(150, 40, 27, 1);
	font-weight: bold;
}

.fumble{
	text-align: center;
	margin-top: 5rem;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/


@media all and (max-width:480px){

	body{
		height: 100vh;
	}

	main{
		max-width: 100%;
	}

	.pbg{
		padding: 1rem;
	}

	button.btn{
		font-weight: bold;
		margin-right: 0;
		background-color: rgba(238, 238, 238,0.9);
	}

	.card{
		margin-left: 0;
		margin-right: 0;
	}

}